<template>
  <div class="card">
    <div class="card-body">
      <div class="alert-primary p-1">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="m-0">
              {{ UserData.agent_username }}
            </a>
          </li>
        </ol>
      </div>
      <div class="row mb-md-2">
        <div class="col-sm-12 col-md-6" />
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right mt-1"
          ><label
            class="d-inline-flex align-items-center"
          >
            Search:
            <input
              type="search"
              placeholder="Search..."
              autocomplete="off"
              class="form-control form-control-sm ml-2 form-control"
            ></label></div>
        </div>
      </div>
      <div class="table-responsive mb-0">
        <div class="table-striped table-bordered table-responsive-md">
          <table
            class="table b-table"
            aria-rowcount="22"
          >

            <thead class="thead-dark">

              <tr
                role="row"
                class=""
              >
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="1"
                  class="text-center"
                >
                  <div>ลำดับ</div>
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="2"
                  aria-sort="none"
                  class="text-left"
                >
                  <div>ชื่อเข้าใช้งาน</div>
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="3"
                  aria-sort="none"
                  class="text-center"
                >
                  <div>ระดับ</div>
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="4"
                  aria-sort="none"
                  class="text-center"
                >
                  <div>ชื่อสกุล</div>
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="5"
                  aria-sort="none"
                  class="text-center"
                >
                  <div>สกุล</div>
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="5"
                  aria-sort="none"
                  class="text-center"
                >
                  <div>เครดิต</div>
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="6"
                  aria-sort="none"
                  class="text-center"
                >
                  <div>สมัครเมื่อ</div>
                </th>
                <!-- <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="7"
                  aria-sort="none"
                  class="text-center"
                >
                  <div>เข้าใช้งานล่าสุด</div>
                </th> -->
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="8"
                  class="text-right"
                >
                  <div>กระเป๋า</div>
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  tabindex="0"
                  aria-colindex="9"
                  class="text-center"
                >
                  <div>เติมเครดิต - ถอนเครดิต</div>
                </th>
              </tr>
            </thead>
            <tbody>

              <tr
                v-for="(item, index) in agent_list"
                :key="index"
                role="row"
                aria-rowindex="1"
                class=""
              >
                <td
                  aria-colindex="1"
                  role="cell"
                  class="text-center"
                >
                  {{ index + 1 }}
                </td>
                <td
                  aria-colindex="2"
                  role="cell"
                  class="text-left"
                >
                  <router-link :to="{ name: 'edit-agent', params: { id: item.id } }">
                    {{ item.username }}</router-link>
                </td>
                <td
                  aria-colindex="3"
                  role="cell"
                  class="text-center"
                >
                  <span class="pro-user-desc badge badge-pill badge-warning">Agent</span>
                </td>
                <td
                  aria-colindex="4"
                  role="cell"
                  class="text-center"
                >
                  {{ item.name }}
                </td>
                <td
                  aria-colindex="5"
                  role="cell"
                  class="text-center"
                >
                  {{ item.lastname }}
                </td>
                <td
                  aria-colindex="5"
                  role="cell"
                  class="text-center"
                >
                  {{ item.credit }}
                </td>
                <td
                  aria-colindex="6"
                  role="cell"
                  class="text-center"
                >
                  {{ item.created_at }}
                  <!-- <span>14/03/2022<br> 02:25:41</span> -->
                </td>
                <!-- <td
                  aria-colindex="7"
                  role="cell"
                  class="text-center"
                >
                  <span>29/03/2022<br> 01:40:05</span>
                </td> -->
                <td
                  aria-colindex="8"
                  role="cell"
                  class="text-right"
                >
                  <span class="font-weight-bold tx-success">-</span>
                </td>
                <td
                  aria-colindex="9"
                  role="cell"
                  class="text-center"
                >
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="success"
                    class="btn-icon mx-1"
                    @click="showModalCredit(item.id)"
                  >
                    <feather-icon icon="DollarSignIcon" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="danger"
                    class="btn-icon mx-1"
                    @click="ModalWithdrawCredit(item.id, item.credit)"
                  >
                    <feather-icon icon="DollarSignIcon" />
                  </b-button>
                  <!-- <b-dropdown
                    id="dropdown-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="จัดการสมาชิก"
                    variant="primary"
                  >
                    <b-dropdown-item>ดูข้อมูลสมาชิก</b-dropdown-item>
                    <b-dropdown-item>Option 2</b-dropdown-item>
                    <b-dropdown-item>Option 3</b-dropdown-item>
                  </b-dropdown> -->
                </td>
              </tr>
            </tbody>
          </table>
          <b-modal
            ref="modal-2"
            title="เติมเครดิต"
            ok-title="Submit"
            @ok="submit_credit"
          >
            <div
              role="group"
              class="input-group input-group-sm"
            >
              <input
                id="__BVID__434"
                v-model="credit"
                type="number"
                class="form-control"
                @input="credit = credit > AgentData.balance ? AgentData.balance : credit"
              >
              <div class="input-group-append">
                <div class="input-group-text">
                  {{ AgentData ? Commas(AgentData.balance) : 0 }}
                </div>
              </div>
            </div>
            <!-- <b-form-group
              label="เครดิต"
              label-for="vi-first-name"
            >
              <b-form-input
                v-model="credit"
                type="number"
                placeholder="เครดิต"
              />
            </b-form-group> -->
          </b-modal>
          <b-modal
            ref="modal-3"
            title="ถอนเครดิต"
            ok-title="Submit"
            @ok="withdraw_credit"
          >
            <div
              role="group"
              class="input-group input-group-sm"
            >
              <input
                id="__BVID__434"
                v-model="credit"
                type="number"
                class="form-control"
                @input="credit = credit > SubAgentCredit ? SubAgentCredit : credit"
              >
              <div class="input-group-append">
                <div class="input-group-text">
                  {{ SubAgentCredit ? Commas(SubAgentCredit) : 0 }}
                </div>
              </div>
            </div>
            <!-- <b-form-group
              label="เครดิต"
              label-for="vi-first-name"
            >
              <b-form-input
                v-model="credit"
                type="number"
                placeholder="เครดิต"
              />
            </b-form-group> -->
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
// BDropdown, BDropdownItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    // BDropdown,
    // BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      status: false,
      setShare: false,
      name: '',
      lastname: '',
      tel: '',
      line_id: '',
      PasswordValue: '',
      passwordCon: '',
      copy_by: '',
      excess_percent: 80,
      huay_status: 0,
      currency: 'THB',
      items: [
        { text: 'Home' },
        { text: 'User' },
        { text: 'User List' },
      ],
      userOp: [
        { title: 'Admin', value: 'admin' },
        { title: 'User', value: 'user' },
      ],
      excessOp: [
        { value: 80, text: '80%' },
        { value: 90, text: '90%' },
        { value: 100, text: '100%' },
      ],
      currencyOp: [
        { value: 'THB', text: 'THB' },
        { value: 'USD', text: 'USD' },
      ],
      excess: 0,
      agent_list: [],
      EditID: null,
      credit: 0,
      AgentData: [],
      SubAgentCredit: 0,
    }
  },
  mounted() {
    this.GetAgentList()
    this.GetAgentData()
  },
  methods: {
    showModalCredit(id) {
      this.EditID = id
      this.GetAgentData()
      this.$refs['modal-2'].show()
    },
    ModalWithdrawCredit(id, credit) {
      this.EditID = id
      this.SubAgentCredit = credit
      this.GetAgentList()
      this.$refs['modal-3'].show()
      console.log(this.EditID, this.SubAgentCredit)
    },
    async GetAgentList() {
      try {
        const { data: res } = await this.$http.get('lotto/ag/showagent')
        this.agent_list = res
      } catch (error) {
        console.log(error)
      }
    },
    GetAgentData() {
      this.$http
        .get('agent/GetMenuData')
        .then(response => {
          this.AgentData = response.data
        })
        .catch(error => console.log(error))
    },
    submit_credit() {
      if (!this.credit || this.credit <= 0) {
        this.$bvToast.toast('กรุณากรอกเครติดที่จะเติม', {
          title: 'แจ้งเตือน',
          variant: 'warning',
          solid: true,
          appendToast: true,
          autoHideDelay: 3000,
        })
        this.credit = null
      } else {
        this.$http
          .post('admin/DipositCredit', {
            id: this.EditID,
            credit: this.credit,
          })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.GetAgentData()
            this.GetAgentList()
            this.credit = 0
            this.EditID = 0
            this.$refs['modal-2'].hide()
            this.$bvToast.toast('เติมเครดิตสำเร็จ', {
              title: 'แจ้งเตือน',
              variant: 'success',
              solid: true,
              appendToast: true,
              autoHideDelay: 3000,
            })
          })
          .catch(error => {
            this.credit = null
            this.adminData = ''
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: 'แจ้งเตือน',
              variant: 'danger',
              solid: true,
              appendToast: true,
              autoHideDelay: 3000,
            })
          })
      }
    },
    withdraw_credit() {
      if (!this.credit || this.credit <= 0) {
        this.$bvToast.toast('กรุณากรอกเครติดที่จะเติม', {
          title: 'แจ้งเตือน',
          variant: 'warning',
          solid: true,
          appendToast: true,
          autoHideDelay: 3000,
        })
        this.credit = null
      } else {
        this.$http
          .post('admin/WithdrawCredit', {
            id: this.EditID,
            credit: this.credit,
          })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.GetAgentData()
            this.GetAgentList()
            this.credit = 0
            this.EditID = 0
            this.SubAgentCredit = 0
            this.$refs['modal-3'].hide()
            this.$bvToast.toast('ถอนเครดิตสำเร็จ', {
              title: 'แจ้งเตือน',
              variant: 'success',
              solid: true,
              appendToast: true,
              autoHideDelay: 3000,
            })
          })
          .catch(error => {
            this.credit = null
            this.adminData = ''
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: 'แจ้งเตือน',
              variant: 'danger',
              solid: true,
              appendToast: true,
              autoHideDelay: 3000,
            })
          })
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      })
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.breadcrumbs {
  font-size: 1rem !important;
}

.breadcrumbs {
  background-color: #4d56e9 !important;
}

.breadcrumbs-item.active,
.breadcrumbs-item a {
  color: #fff !important;
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #dedcfb;
  border-radius: 3px;
}

.active {
  background-color: #0068d0 !important;
  color: #fff !important;
}

.input-group-text {
  background-color: #0068d0 !important;
  color: #fff !important;
}
</style>
